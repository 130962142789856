function main() {
  /*********************
  GLOBAL
  **********************/
  const $canvasLogo = document.getElementById('logoCanvas');
  const $canvasPosting = document.getElementById('postingCanvas');

  const $logoLink = document.getElementById('dl-logo');
  const $postingLink = document.getElementById('dl-post');

  svgTop = new Image();
  svgLeft = new Image();
  svgRight = new Image();
  svgBackground_1x1 = new Image();

  getImageFromURLandConvertToBase64('assets/27percent_top.svg', svgTop);
  getImageFromURLandConvertToBase64('assets/27percent_left.svg', svgLeft);
  getImageFromURLandConvertToBase64('assets/27percent_right.svg', svgRight);
  getImageFromURLandConvertToBase64(
    'assets/27percent_bg_1x1.svg',
    svgBackground_1x1
  );

  let cropping;
  let IMAGE = new Image();
  IMAGE.onload = () => {
    // make #result visible
    // TODO: nicer effect (keyframe animation?)
    document.getElementById('result').classList.remove('hidden');

    // also hide the droparea
    // TODO: nicer effect (keyframe animation?)
    document.getElementById('drop-area').classList.add('hidden');

    showWarning();

    // e.g. firefox does on update images sizes on load event
    // to properly calculate we need to svg hardcoded values
    // to image width and image height
    // check for any image width to see if it is correctly set
    // if (!svgLeft.width) ImageSizeFix();
    ImageSizeFix();
    drawAllCanvases(IMAGE);
  };

  const $copyBtn = document.getElementById('copy-btn');
  $copyBtn.addEventListener('click', handleCopyToClipboard);

  // add click event listener to buttons logo-position-links etc.
  document
    .getElementById('logo-position-links')
    .addEventListener('click', (e) => {
      handleChangePosition(e, 'links');
    });
  document
    .getElementById('logo-position-oben')
    .addEventListener('click', (e) => {
      handleChangePosition(e, 'top');
    });
  document
    .getElementById('logo-position-rechts')
    .addEventListener('click', (e) => {
      handleChangePosition(e, 'rechts');
    });

  document.getElementById('reset').addEventListener('click', (e) => {
    resetImageDisplay();
  });

  function ImageSizeFix() {
    const sizes = {
      top: { width: 300, height: 55 },
      left: { width: 168, height: 150 },
      right: { width: 179, height: 150 },
      bg: { width: 1080, height: 1080 },
    };
    svgBackground_1x1.width = sizes.bg.width;
    svgBackground_1x1.height = sizes.bg.height;
    svgLeft.width = sizes.left.width;
    svgLeft.height = sizes.left.height;
    svgRight.width = sizes.right.width;
    svgRight.height = sizes.right.height;
    svgTop.width = sizes.top.width;
    svgTop.height = sizes.top.height;
  }

  /*********************
  DRAG AND DROP
  **********************/
  const $dropArea = document.body;
  const $dimmer = document.getElementById('dimmer');
  const $inputFile = document.getElementById('input-file');

  $dropArea.addEventListener('dragenter', (e) => {
    e.preventDefault();
    $dimmer.classList.remove('hidden');
  });
  $dimmer.addEventListener('dragover', (e) => e.preventDefault());
  $dimmer.addEventListener('dragleave', (e) => {
    e.preventDefault();
    $dimmer.classList.add('hidden');
  });
  $dimmer.addEventListener('drop', handleFileDrop);
  $inputFile.addEventListener('change', handleFileChange, false);

  /*********************
  CANVAS
  **********************/

  function resetCanvasSize($canvas) {
    const $container = $canvas.parentNode;
    $canvas.width = $container.clientWidth;
    $canvas.height = $container.clientHeight;
  }

  function showWarning() {
    document.getElementById('warning_small').classList.add('hidden');
    document.getElementById('warning_big').classList.add('hidden');
    // show warning if image is too small
    if (IMAGE.width < 100) {
      document.getElementById('warning_small').classList.remove('hidden');
    }

    if (IMAGE.width > 2000) {
      document.getElementById('warning_big').classList.remove('hidden');
    }
  }

  function getActualImageDimensions(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;
    let minX = canvas.width;
    let minY = canvas.height;
    let maxX = -1;
    let maxY = -1;
    for (let x = 0; x < canvas.width; x++) {
      for (let y = 0; y < canvas.height; y++) {
        var index = (y * canvas.width + x) * 4;
        var alpha = pixels[index + 3];
        if (alpha > 0) {
          if (x < minX) minX = x;
          if (y < minY) minY = y;
          if (x > maxX) maxX = x;
          if (y > maxY) maxY = y;
        }
      }
    }
    const actualWidth = maxX - minX;
    const actualHeight = maxY - minY;

    return {
      actualWidth,
      actualHeight,
      xTransparentStart: minX < canvas.height ? minX : 0,
      yTransparentStart: minY < canvas.width ? minY : 0,
    };
  }

  function getCanvasSizes(width, height) {
    const offsetWidth = Math.floor(width * (27 / 100));

    // calculate drawing positions
    // for placing SVG on left and img on right
    const svgLeftWidth = Math.floor(svgLeft.width * (height / svgLeft.height));
    const fullWidthForSvgLeft = width + svgLeftWidth - offsetWidth;
    const imgRightStartDrawPosition = svgLeftWidth - offsetWidth;

    // for placing SVG on right and img on left
    const svgRightWidth = Math.floor(
      svgRight.width * (height / svgRight.height)
    );
    const svgRightStartDrawPosition = width - offsetWidth;
    const fullWidthForSvgRight = width + svgRightWidth - offsetWidth;

    // calculate drawing position for top
    const offsetHeight = Math.floor(height * (27 / 100));
    const svgTopHeight = Math.floor(svgTop.height * (width / svgTop.width));
    const imgBottomStartDrawPosition = svgTopHeight - offsetHeight;
    const fullHeightForSvgTop = height + svgTopHeight - offsetHeight;
    return {
      svgRightPos: {
        pos: 'rechts',
        fullWidth: fullWidthForSvgRight,
        startDrawPosition: svgRightStartDrawPosition,
        width: svgRightWidth,
        height,
      },
      svgLeftPos: {
        pos: 'links',
        fullWidth: fullWidthForSvgLeft,
        startDrawPosition: imgRightStartDrawPosition,
        width: svgLeftWidth,
        height,
      },
      svgTopPos: {
        pos: 'top',
        fullHeight: fullHeightForSvgTop,
        startDrawPosition: imgBottomStartDrawPosition,
        width,
        height: svgTopHeight,
      },
    };
  }

  function drawAllCanvases(img) {
    // fix canvas sizen on inital page load
    [$canvasLogo, $canvasPosting].forEach(($canvas) =>
      resetCanvasSize($canvas)
    );

    cropping = getActualImageDimensions(img);
    const { svgLeftPos } = getCanvasSizes(
      cropping.actualWidth,
      cropping.actualHeight
    );
    drawLogoCanvas($canvasLogo, svgLeftPos, img);
    drawSquareCanvas($canvasPosting, svgLeftPos, img);
  }

  function resizeCanvasContainer($canvas, svgSizes) {
    const $container = $canvas.parentNode;

    let correctHeight = svgSizes.fullHeight
      ? svgSizes.fullHeight
      : svgSizes.height;
    let correctWidth = svgSizes.fullWidth ? svgSizes.fullWidth : svgSizes.width;
    if (correctWidth > correctHeight) {
      $container.style.width = '350px';
      $container.style.height = null;
    } else {
      $container.style.height = '350px';
      $container.style.width = null;
    }
    $container.style.setProperty(
      '--canvas-aspect-ratio',
      correctWidth / correctHeight
    );
  }
  function drawLogoCanvas($canvas, svg, img) {
    const position = svg.pos;
    switch (position) {
      case 'rechts':
        drawRightCanvasOption($canvas, svg, img);
        break;
      case 'top':
        drawTopCanvasOption($canvas, svg, img);
        break;
      default:
        drawLeftCanvasOption($canvas, svg, img);
    }
    // update link
    $logoLink.download = `27%_VON_UNS_Logo_${position}.png`;
    $logoLink.href = $canvas.toDataURL('image/png');
  }

  function drawRightCanvasOption($canvas, svg, img) {
    resizeCanvasContainer($canvas, svg);
    $canvas.width = svg.fullWidth;
    $canvas.height = svg.height;
    const ctx = $canvas.getContext('2d');
    // draw logo
    ctx.drawImage(
      img,
      cropping.xTransparentStart,
      cropping.yTransparentStart,
      cropping.actualWidth,
      cropping.actualHeight,
      0,
      0,
      cropping.actualWidth,
      cropping.actualHeight
    );
    ctx.clearRect(svg.startDrawPosition, 0, svg.width, svg.height);
    // draw SVG
    ctx.drawImage(svgRight, svg.startDrawPosition, 0, svg.width, svg.height);
  }

  function drawLeftCanvasOption($canvas, svg, img) {
    resizeCanvasContainer($canvas, svg);
    $canvas.width = svg.fullWidth;
    $canvas.height = svg.height;
    const ctx = $canvas.getContext('2d');
    // draw logo
    ctx.drawImage(
      img,
      cropping.xTransparentStart,
      cropping.yTransparentStart,
      cropping.actualWidth,
      cropping.actualHeight,
      svg.startDrawPosition,
      0,
      cropping.actualWidth,
      cropping.actualHeight
    );
    ctx.clearRect(0, 0, svg.width, img.height);
    // draw SVG
    ctx.drawImage(svgLeft, 0, 0, svg.width, svg.height);
  }

  function drawTopCanvasOption($canvas, svg, img) {
    resizeCanvasContainer($canvas, svg);
    $canvas.width = svg.width;
    $canvas.height = svg.fullHeight;
    const ctx = $canvas.getContext('2d');
    // draw logo
    ctx.drawImage(
      img,
      cropping.xTransparentStart,
      cropping.yTransparentStart,
      cropping.actualWidth,
      cropping.actualHeight,
      0,
      svg.startDrawPosition,
      cropping.actualWidth,
      cropping.actualHeight
    );
    ctx.clearRect(0, 0, svg.width, svg.height);
    // draw SVG
    ctx.drawImage(svgTop, 0, 0, svg.width, svg.height);
  }
  function drawSquareCanvas($canvas, svg, img) {
    // get full width and height of and logo svg
    let correctHeight = svg.fullHeight ? svg.fullHeight : svg.height;
    let correctWidth = svg.fullWidth ? svg.fullWidth : svg.width;
    // get the leading (largest size) to draw a correct canvas size
    const canvasSize = 1080;
    $canvas.width = canvasSize;
    $canvas.height = canvasSize;
    const canvasGridSize = 9;
    const logoAreaGridWidth = 7;
    const logoAreaGridHeight = 6;
    const logoAreaAspectRatio = logoAreaGridWidth / logoAreaGridHeight;
    const imgAspectRatio = correctWidth / correctHeight;
    const isWiderThanHigherAsDrawArea = imgAspectRatio > logoAreaAspectRatio;

    const xLogoAreaStartPosition = Math.floor(canvasSize / canvasGridSize);
    const xLogoAreaEndPosition =
      xLogoAreaStartPosition * (logoAreaGridWidth + 1);
    const logoAreaWidth = xLogoAreaEndPosition - xLogoAreaStartPosition;
    const yLogoAreaStartPosition = Math.floor(canvasSize / canvasGridSize);
    const yLogoAreaEndPosition =
      yLogoAreaStartPosition * (logoAreaGridHeight + 1);
    const logoAreaHeight = yLogoAreaEndPosition - yLogoAreaStartPosition;
    const xLogoCenter = Math.floor(canvasSize / 2);
    const yLogoCenter = Math.floor(
      (yLogoAreaStartPosition + yLogoAreaEndPosition) / 2
    );
    const dimensions = {
      canvasSize,
      isWiderThanHigherAsDrawArea,
      xLogoAreaStartPosition,
      xLogoAreaEndPosition,
      yLogoAreaStartPosition,
      yLogoAreaEndPosition,
      logoAreaWidth,
      logoAreaHeight,
      xLogoCenter,
      yLogoCenter,
      correctWidth,
      correctHeight,
      imgAspectRatio,
    };
    /***** DEBUGING SQUARE CANVAS TOOLS ************
    console.log(svg, dimensions);
    ctx.fillStyle = "blue";
    ctx.fillRect(dimensions.xLogoAreaStartPosition, dimensions.yLogoAreaStartPosition, dimensions.logoAreaWidth, dimensions.logoAreaHeight)
    */
    const position = svg.pos;
    switch (position) {
      case 'rechts':
        drawSquareRightCanvasOption($canvas, svg, img, dimensions);
        break;
      case 'top':
        drawSquareTopCanvasOption($canvas, svg, img, dimensions);
        break;
      default:
        drawSquareLeftCanvasOption($canvas, svg, img, dimensions);
    }

    // update url
    $postingLink.href = $canvas.toDataURL('image/png');
  }
  function drawSquareTopCanvasOption($canvas, svg, img, dimensions) {
    const ctx = $canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, dimensions.canvasSize, dimensions.canvasSize);
    ctx.drawImage(
      svgBackground_1x1,
      0,
      0,
      svgBackground_1x1.width,
      svgBackground_1x1.height
    );

    // draw logo in logo draw area
    if (dimensions.isWiderThanHigherAsDrawArea) {
      const widthAspectRatio =
        dimensions.logoAreaWidth / dimensions.correctWidth;
      let drawHeight = cropping.actualHeight * widthAspectRatio;
      let xStartDrawPos = dimensions.xLogoAreaStartPosition;
      let xStartDrawLogoPos = xStartDrawPos;
      let yStartDrawPos = dimensions.yLogoCenter - Math.floor(drawHeight / 2);
      let yStartDrawLogoPos =
        yStartDrawPos + svg.startDrawPosition * widthAspectRatio;
      // draw main logo img
      ctx.drawImage(
        img,
        cropping.xTransparentStart,
        cropping.yTransparentStart,
        cropping.actualWidth,
        cropping.actualHeight,
        xStartDrawLogoPos,
        yStartDrawLogoPos,
        dimensions.logoAreaWidth,
        drawHeight
      );
      // clear image overflow
      ctx.fillRect(
        xStartDrawPos,
        yStartDrawPos,
        dimensions.logoAreaWidth,
        svg.height * widthAspectRatio
      );
      ctx.fillRect(xStartDrawPos, 0, dimensions.logoAreaWidth, yStartDrawPos);
      // draw SVG
      ctx.drawImage(
        svgTop,
        xStartDrawPos,
        yStartDrawPos,
        dimensions.logoAreaWidth,
        svg.height * widthAspectRatio
      );
    } else {
      const heightAspectRatio =
        dimensions.logoAreaHeight / dimensions.correctHeight;
      let drawWidth = Math.floor(cropping.actualWidth * heightAspectRatio);
      let imgDrawHeight = Math.floor(cropping.actualHeight * heightAspectRatio);
      let xStartDrawPos = dimensions.xLogoCenter - Math.floor(drawWidth / 2);
      let xStartDrawLogoPos = xStartDrawPos;
      let yStartDrawPos = dimensions.yLogoAreaStartPosition;
      let yStartDrawLogoPos =
        dimensions.yLogoAreaStartPosition +
        Math.floor(svg.startDrawPosition * heightAspectRatio);
      // draw main logo img
      ctx.drawImage(
        img,
        cropping.xTransparentStart,
        cropping.yTransparentStart,
        cropping.actualWidth,
        cropping.actualHeight,
        xStartDrawLogoPos,
        yStartDrawLogoPos,
        drawWidth,
        imgDrawHeight
      );
      // clear image overflow
      ctx.fillRect(
        0,
        0,
        dimensions.canvasSize,
        dimensions.yLogoAreaStartPosition
      );
      ctx.fillRect(
        xStartDrawPos,
        yStartDrawPos,
        drawWidth,
        svg.height * heightAspectRatio
      );
      // draw SVG
      ctx.drawImage(
        svgTop,
        xStartDrawPos,
        yStartDrawPos,
        drawWidth,
        svg.height * heightAspectRatio
      );
    }
  }
  function drawSquareRightCanvasOption($canvas, svg, img, dimensions) {
    const ctx = $canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, dimensions.canvasSize, dimensions.canvasSize);
    ctx.drawImage(
      svgBackground_1x1,
      0,
      0,
      svgBackground_1x1.width,
      svgBackground_1x1.height
    );

    const widthAspectRatio = dimensions.logoAreaWidth / dimensions.correctWidth;
    let xStartDrawPos = Math.floor(
      dimensions.xLogoAreaStartPosition +
        svg.startDrawPosition * widthAspectRatio
    );
    let xStartDrawLogoPos = dimensions.xLogoAreaStartPosition;
    let yStartDrawPos =
      dimensions.yLogoCenter -
      Math.floor((dimensions.correctHeight * widthAspectRatio) / 2);
    let yStartDrawLogoPos = yStartDrawPos;
    // draw main logo img
    ctx.drawImage(
      img,
      cropping.xTransparentStart,
      cropping.yTransparentStart,
      cropping.actualWidth,
      cropping.actualHeight,
      xStartDrawLogoPos,
      yStartDrawLogoPos,
      cropping.actualWidth * widthAspectRatio,
      cropping.actualHeight * widthAspectRatio
    );
    ctx.fillRect(
      xStartDrawPos,
      yStartDrawPos,
      dimensions.logoAreaWidth,
      svg.height * widthAspectRatio
    );
    // draw SVG
    ctx.drawImage(
      svgRight,
      xStartDrawPos,
      yStartDrawPos,
      svg.width * widthAspectRatio,
      svg.height * widthAspectRatio
    );
  }
  function drawSquareLeftCanvasOption($canvas, svg, img, dimensions) {
    const ctx = $canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, dimensions.canvasSize, dimensions.canvasSize);
    ctx.drawImage(
      svgBackground_1x1,
      0,
      0,
      svgBackground_1x1.width,
      svgBackground_1x1.height
    );

    const widthAspectRatio = dimensions.logoAreaWidth / dimensions.correctWidth;
    let xStartDrawPos = dimensions.xLogoAreaStartPosition;
    let xStartDrawLogoPos = Math.floor(
      dimensions.xLogoAreaStartPosition +
        svg.startDrawPosition * widthAspectRatio
    );
    let yStartDrawPos =
      dimensions.yLogoCenter -
      Math.floor((dimensions.correctHeight * widthAspectRatio) / 2);
    let yStartDrawLogoPos = yStartDrawPos;
    // draw main logo img
    ctx.drawImage(
      img,
      cropping.xTransparentStart,
      cropping.yTransparentStart,
      cropping.actualWidth,
      cropping.actualHeight,
      xStartDrawLogoPos,
      yStartDrawLogoPos,
      cropping.actualWidth * widthAspectRatio,
      cropping.actualHeight * widthAspectRatio
    );
    ctx.fillRect(
      0,
      yStartDrawPos,
      xStartDrawPos + svg.width * widthAspectRatio,
      svg.height * widthAspectRatio
    );
    // draw SVG
    ctx.drawImage(
      svgLeft,
      xStartDrawPos,
      yStartDrawPos,
      svg.width * widthAspectRatio,
      svg.height * widthAspectRatio
    );
  }

  /*************************
  HANDLERS AND UTILS
  *************************/
  function resetImageDisplay() {
    IMAGE.src = '';
    document.getElementById('result').classList.add('hidden');
    document.getElementById('drop-area').classList.remove('hidden');
    $inputFile.value = '';
  }

  function handleFileChange(e) {
    let reader = new FileReader();
    reader.onload = function (e) {
      IMAGE.src = e.target.result;

      // track image load event in Piwik Pro with custom event ImageLoad
      _paq.push(['trackEvent', 'ImageLoad', 'Image loaded', 'Image loaded']);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  function handleCopyToClipboard(evt) {
    const $copyContent = evt.target.previousElementSibling;
    const cleanCopy = $copyContent.textContent.replace(/[\t \r]+/g, ' ');

    navigator.clipboard
      .writeText(cleanCopy)
      .then(
        () => {
          _paq.push([
            'trackEvent',
            'Copy',
            'Copy to clipboard',
            'Copy to clipboard',
          ]);

          evt.target.classList.add('copied');
        },
        (err) => {
          evt.target.classList.add('copy-failed');
        }
      )
      .catch((err) => {
        evt.target.classList.add('copy-failed');
      });
  }

  function handleChangePosition(evt, position) {
    evt.target.blur();

    // remove active class from all buttons in position-selection div
    document.querySelectorAll('.position-selection button').forEach((el) => {
      el.classList.remove('active');
    });
    evt.target.classList.add('active');

    const { svgRightPos, svgLeftPos, svgTopPos } = getCanvasSizes(
      cropping.actualWidth,
      cropping.actualHeight
    );
    let svg;
    switch (position) {
      case 'rechts':
        svg = svgRightPos;
        break;
      case 'top':
        svg = svgTopPos;
        break;
      default:
        svg = svgLeftPos;
    }
    drawLogoCanvas($canvasLogo, svg, IMAGE);
    drawSquareCanvas($canvasPosting, svg, IMAGE);
  }

  function handleFileDrop(e) {
    e.preventDefault();
    $dropArea.classList.remove('drag-over');
    $dimmer.classList.add('hidden');
    $inputFile.files = e.dataTransfer.files;
    $inputFile.dispatchEvent(new Event('change'));
  }

  function getImageFromURLandConvertToBase64(url, image) {
    fetch(url)
      .then((response) => response.text())
      .then((svgText) => {
        // Encode the SVG data as base64
        const base64svg = btoa(svgText);
        // Set image src attribute to the base64 data URL
        image.src = `data:image/svg+xml;base64,${base64svg}`;
      });
  }

  /*
   * Modal
   *
   * Pico.css - https://picocss.com
   * Copyright 2019-2024 - Licensed under MIT
   */

  // Config
  const isOpenClass = 'modal-is-open';
  const openingClass = 'modal-is-opening';
  const closingClass = 'modal-is-closing';
  const scrollbarWidthCssVar = '--pico-scrollbar-width';
  const animationDuration = 400; // ms
  let visibleModal = null;

  const toggleModal = (event) => {
    event.preventDefault();
    const modal = document.getElementById(event.currentTarget.dataset.dialog);
    if (!modal) return;
    modal && (isModalOpen(modal) ? closeModal(modal) : openModal(modal));
  };

  const isModalOpen = (modal) =>
    modal.hasAttribute('open') && modal.getAttribute('open') !== 'false';

  const openModal = (modal) => {
    const { documentElement: html } = document;
    const scrollbarWidth = getScrollbarWidth();
    if (scrollbarWidth) {
      html.style.setProperty(scrollbarWidthCssVar, `${scrollbarWidth}px`);
    }
    html.classList.add(isOpenClass, openingClass);
    setTimeout(() => {
      visibleModal = modal;
      html.classList.remove(openingClass);
    }, animationDuration);
    modal.setAttribute('open', true);
  };

  const closeModal = (modal) => {
    visibleModal = null;
    const { documentElement: html } = document;
    html.classList.add(closingClass);
    setTimeout(() => {
      html.classList.remove(closingClass, isOpenClass);
      html.style.removeProperty(scrollbarWidthCssVar);
      modal.removeAttribute('open');
    }, animationDuration);
  };

  document.addEventListener('click', (event) => {
    if (visibleModal === null) return;
    const modalContent = visibleModal.querySelector('article');
    const isClickInside = modalContent.contains(event.target);
    !isClickInside && closeModal(visibleModal);
  });

  const closeButtons = document.querySelectorAll("[rel='prev']");
  closeButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      if (visibleModal === null) return;

      closeModal(visibleModal);
    });
  });

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape' && visibleModal) {
      closeModal(visibleModal);
    }
  });

  const getScrollbarWidth = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    return scrollbarWidth;
  };

  const isScrollbarVisible = () => {
    return document.body.scrollHeight > screen.height;
  };

  // click on link with id datenschutzerklaerung or impressum toggles modal
  document
    .getElementById('datenschutzerklaerung')
    .addEventListener('click', toggleModal);
  document.getElementById('impressum').addEventListener('click', toggleModal);
}
main();
